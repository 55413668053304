/* js/appModal.js */


$('.js_appModalTrigger').click(function (e) {

    e.preventDefault();

    $('.appModal').find('.extraImgs').remove();

    $(this).closest('.infoBox').find('.extraImgs').clone().prependTo('.carousel');

    $('#carousel-example-generic').carousel();
});