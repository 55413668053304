jQuery( document ).ready(function( $ ) {


 //$('.menu').dropit();


 //$( '.swipebox' ).swipebox();

 $('.callOut').matchHeight();
 $('.imgBox__img').matchHeight();
 $('.iconBox__heading').matchHeight();


  //$('.navMain').dropit();

 
    







});